

@import 'libs/normalize.css';


@font-face {
    font-family: 'CrashCTT';
    font-style: normal;
    font-weight: 400;
    src: local('CrashCTT'), local('CrashCTT-Regular'),
        url(../fonts/crashctt.woff) format('woff')
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

/*! GLOBAL !*/

* {
	box-sizing: border-box;
	flex-shrink: 0;
}
a {
	color: #1e1e1e;
	text-decoration: underline;
}
iframe {
	border: none;
}
body {
	// font-family: 'CrashCTT';
	font-family: 'Nunito';
	font-size: 18px;
	color: #1e1e1e;
}
button {
	padding: 0;
}
.container {
	width: 1200px;
	margin: 0 auto;
	position: relative;
}

.content ul {
	list-style-type: none;
	padding: 0;
}
.content ul li {
	display: flex;
	gap: 24px;
	margin-bottom: 20px;
}
.content ul li::before {
	content: '';
	background-color: #B8DFE4;
	width: 18px;
	height: 18px;
	display: block;
	flex-shrink: 0;
	border-radius: 50%;
}
.button {
	display: inline-flex;
	border: 1px solid #1E1E1E;
	padding: 12px 30px;
	align-self: flex-start;
	text-decoration: none;
}
.map-section {
	width: 100%;
	height: 740px;
	position: relative;
}
.bg-block {
	background-color: #B8DFE4;
}


.slider-arrow {
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	width: 35px;
	height: 35px;
	background-color: #1E1E1E;
	border-radius: 50%;
}
.slider-arrow-next {
	background-image: url('../img/next.svg');
}
.slider-arrow-prev {
	background-image: url('../img/prev.svg');
}


.page-title {
	margin: 0;
	font-size: 48px;
	font-weight: 600;
	margin-bottom: 38px;
}

.block-title {
	position: relative;
	font-size: 30px;
	font-weight: bold;
	display: flex;
	gap: 12px;
	align-items: stretch;
	margin-bottom: 24px;
	margin-top: 24px;
}
.block-title::before {
	content: '';
	width: 4px;
	// height: 100%;
	flex-shrink: 0;
	background-color: #B8DFE4;

}
.block-subtitle {
	position: relative;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	gap: 12px;
	align-items: stretch;
	margin-bottom: 24px;
	margin-top: 24px;
}
.block-subtitle::before {
	content: '';
	width: 4px;
	flex-shrink: 0;
	// height: 100%;
	background-color: #B8DFE4;

}


.date {
	font-weight: 500;
	padding: 4px 12px;
	background-color: #E8E8E8;
}



.files {
	margin-top: 60px;
	margin-bottom: 60px;
	display: flex;
	flex-wrap: wrap;
	gap: 100px;
}
.files-item {
	display: flex;
	gap: 26px;
	align-items: center;
	text-decoration: none;
}
.files-item-icon {
	width: 40px;
	height: 40px;
	background-color: #B8DFE4;
	border-radius: 50%;
	background-image: url('../img/arrow-bottom.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.files-item-title {
	margin-bottom: 4px;
	font-weight: bold;
}


.block-contacts {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 24px;
	margin-bottom: 24px;
}
.block-contacts-item {
	display: flex;
	align-items: center;
	gap: 40px;
}


.block-list {
	column-count: 3;
	margin-top: 24px;
	margin-bottom: 24px;
}
.block-list a{
	padding-bottom: 20px;
	display: block;
	text-decoration: none;
}

/*! GLOBAL !*/

/*! HEADER !*/

.main-header {

}
.main-header-top {
	height: 160px;
	background-color: #D9D9D9;
	background-image: url('../img/header-top.svg');
	display: flex;
	align-items: center;
	
}
.main-header-top .container {
	display: flex;
	justify-content: flex-end;
}
.lang-select {
	padding: 6px 12px;
	background-color: #B8DFE4;
}
.lang-select span {
	font-weight: bold;
}
.lang-select a{
	text-decoration: none;
}
.main-nav {
	background-color: #B8DFE4;
	
}
.main-nav .container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 60px;
}
.main-nav .menu {
	margin: 0;
	padding: 0;
	list-style-type: none;
	font-family: 'CrashCTT';
	font-size: 20px;
	text-transform: uppercase;
	font-weight: bold;
	display: flex;
	align-items: center;
	gap: 40px;
	padding-top: 30px;
	padding-bottom: 30px;
}
.main-nav .menu li {
	display: flex;
}
.main-nav .menu li a {
	color: #000;
	
	text-decoration: none;
}
.search {

}
.search-input {
	display: none;

}
.search-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
}
.bg-grey-block {
	background-color: #E8E8E8;
}
.bg-vinni {
	
	position: relative;
	background-color: #E8E8E8;
	padding: 1px;
}
.bg-vinni > * {
	z-index: 1;
}
.bg-vinni::before {
	content: '';
	height: 100%;
	
	width: 30%;
	display: block;
	background-image: url('../img/vinni2.svg');
	background-position: right bottom;
	// background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	right: 0;
	bottom: 0;
}
.bg-vinni::after {
	content: '';
	
	height: 100%;
	width: 30%;
	display: block;
	background-image: url('../img/vinni1.svg');
	background-position: left bottom;
	// background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	left: 0;
	bottom: 0;
}
.main-block {
	padding-top: 70px;
	padding-bottom: 100px;
}
.main-block .container {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	align-items: center;
	gap: 80px;
}
.main-block-content {
	width: 100%;
}
.main-block-title {
	font-size: 50px;
	font-family: 'CrashCTT';
	margin-bottom: 70px;
}
.main-block-description {
	font-size: 20px;
}
.main-block-slider-container {
	z-index: 3;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	gap: 28px;
}
.main-block-slider {
	flex-shrink: 1;
	position: relative;
	
}

.main-block-slider-item {
	min-height: 1px;
	min-width: 1px;
	height: 100%;
}
.main-block-slider-item-pic {
	height: 300px;
}
.main-block-slider-item-pic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	
}


/*! HEADER !*/


/*! LAST NEWS !*/


.last-news {
	padding: 30px 100px 100px;
	margin-bottom: 140px;
}

.last-news-top {
	margin-bottom: 60px;
}
.last-news-list {
	display: flex;
	flex-direction: column;
	gap: 80px;
}
.last-news-item {
	display: flex;
	gap: 40px;
}
.last-news-item-pic {
	width: 320px;
	height: 250px;
	display: flex;
}
.last-news-item-pic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.last-news-item-content {
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.last-news-item-date {
	font-size: 20px;
	margin-bottom: 28px;

}
.last-news-item-title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 36px;
	text-decoration: none;
}
.last-news-item-more {
	font-size: 20px;
	border: 1px solid #1e1e1e;
	background-color: transparent;
	padding: 12px 30px;
	text-decoration: none;
}
/*! LAST NEWS !*/

/*! VIDEO SECTION !*/
.video-section {
	position: relative;
}
.video-section-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	
}
.video-section-bg::after {
	content: '';
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.82);
	position: absolute;
}
.video-section-bg img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.video-section-content {
	position: relative;
	height: 750px;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.video-section-title {
	color: #fff;
	font-size: 36px;
	margin-bottom: 34px;
}
.video-section-play {
	margin-bottom: 34px;
	width: 106px;
	height: 106px;
}
.video-section-time {
	color: #fff;
	font-size: 36px;
}

/*! VIDEO SECTION !*/

/*! PERSONS SLIDER !*/

.persons-section {
	background-color: #E8E8E8;
	padding-top: 50px;
	padding-bottom: 100px;
	position: relative;
}
.persons-section .slider-arrows {
	position: absolute;
	width: 450px;
	display: flex;
	justify-content: space-between;
	top: 64%;
	left: 50%;
	z-index: 2;
	transform: translate(-50%,-50%);
}
.persons-slider {
	margin-top: 20px;
}
.persons-slider .swiper-wrapper {
	align-items: center;
}
.persons-slider-item {
	
	// transition: .3s;
	padding-top: 120px;
}
.persons-slider-item-content {
	background-color: #fff;
	padding-top: 80px;
	box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
	text-align: center;
	padding-bottom: 20px;
	padding-left: 24px;
	padding-right: 24px;
}

.persons-slider-item-pic {
	z-index: 1;
	position: relative;
	margin: 0 auto;
	width: 150px;
	height: 150px;
	overflow: hidden;
	margin-top: -80px;
	margin-bottom: -70px;
	border-radius: 50%;
	border: 12px solid #1E1E1E;
}
.persons-slider-item-pic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.persons-slider-item.swiper-slide-active .persons-slider-item-content{
	// height: 500px;
	// padding-bottom: 40px;
	// padding-top: 120px;
	// padding-left: 30px;
	// padding-right: 30px;
}

.persons-slider-item.swiper-slide-active .persons-slider-item-pic {
	border: 12px solid #EF4A4A;
	// width: 200px;
	// height: 200px;
	// margin-top: -120px;
	// margin-bottom: -80px;
}
.persons-slider-item.swiper-slide-active .persons-slider-item-date{
	// font-size: 32px;

}
.persons-slider-item.swiper-slide-active .persons-slider-item-name{
	// padding-top: 28px;
	// padding-bottom: 24px;
	// font-size: 30px;
	// margin-top: 24px;
	// margin-bottom: 24px;
}
.persons-slider-item.swiper-slide-active .persons-slider-item-age{
	// font-size: 32px;
}
.persons-slider-item.swiper-slide-active .persons-slider-item-button{
	// font-size: 28px;
	// height: 70px;
	background-color: #EF4A4A;;
}
.persons-slider-item-date {
	color: #EF4A4A;
	// font-size: 24px;
	// font-weight: bold;
}
.persons-slider-item-name {
	font-size: 20px;
	padding-top: 20px;
	padding-bottom: 16px;
	border-top: 1px solid #1E1E1E;
	border-bottom: 1px solid #1E1E1E;
	margin-top: 12px;
	margin-bottom: 20px;
	font-weight: bold;
}
.persons-slider-item-age {
	color: #EF4A4A;
	font-size: 24px;
	font-weight: bold;
}
.persons-slider-item-button {
	font-size: 24px;
	height: 46px;
	background-color: #1E1E1E;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
	gap: 30px;
	
}
.persons-slider-item-button::after {
	content: '';
	width: 10px;
	height: 10px;
	background-color: #fff;
	border-radius: 50%;
}
.persons-slider-item-button::before {
	content: '';
	width: 10px;
	height: 10px;
	background-color: #fff;
	border-radius: 50%;
}

/*! PERSONS SLIDER !*/
/*! PARTNERS SLIDER !*/

.partners-section {
	padding-top: 120px;
	padding-bottom: 120px;
}
.partners-section .slider-container {
	display: flex;
	align-items: center;
	gap: 10px;
	// width: 100%;
}
.slider-container .swiper{
	flex-shrink: 1;
}	
.partners-list-item {
	display: flex;
	align-items: center;
	justify-content: center;
}
.partners-list-item img {
	max-width: 100%;
	max-height: 100%;
}
/*! PARTNERS SLIDER !*/

/*! FOOTER !*/
.main-footer {
	background-color: #B8DFE4;
	padding-top: 70px;
	padding-bottom: 80px;
}

.main-footer-row {
	align-items: center;
	display: grid;
	grid-template-columns: 5fr 1fr 2fr;
	gap: 80px;
	margin-bottom: 70px;
}

.main-footer-row .main-footer-text {
	text-align: right;
}
.main-footer-menu .menu {
	margin: 0;
	padding: 0;
	list-style-type: none;
	font-family: 'CrashCTT';
	font-size: 18px;
	text-transform: uppercase;
	font-weight: bold;
	display: flex;
	gap: 20px;
	flex-direction: column;
	height: 144px;
	flex-wrap: wrap;
}
.main-footer-menu .menu li a {
	text-decoration: none;
}
/*! FOOTER !*/

/*! SOCIAL LIST !*/
.social-links {
	gap: 28px;
	flex-wrap: wrap;
	display: flex;

}
.social-item-link {
	text-decoration: none;
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #1E1E1E;
	border-radius: 50%;
}
/*! SOCIAL LIST !*/

/*! BREADCRUMBS !*/
.breadcrumbs {
	padding-top: 30px;
	padding-bottom: 30px;
	font-size: 14px;
}
.breadcrumbs-list {
	display: flex;
	align-items: center;
	gap: 4px;
}
/*! BREADCRUMBS !*/


/*! FILTER !*/
.search-filter {
	padding: 50px;
	background-color: #B8DFE4;
	margin-bottom: 34px;
}
.search-filter form {
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: space-between;
}
.filter-field {
	display: flex;
	gap: 20px;
	align-items: center;
	// width: 100%;
	flex-wrap: wrap;
}
.filter-field-items {
	display: flex;
	gap: 20px;
	flex: 1;
	flex-wrap: wrap;
}
.filter-fields {
	flex-shrink: 1;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}
.filter-field input {
	flex: 1;
	border: none;
	font-size: 24px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 100%;
}
.filter-field select {
	border: none;
	font-size: 24px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 20px;
	padding-right: 20px;
}
.filter-field-name {
	font-size: 24px;
	flex-shrink: 1;
}

.filter-links {
	margin-top: 40px;
	margin-bottom: 40px;
	font-size: 30px;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}
.filter-links a {
	text-decoration: none;
}
.filter-links a.active {
	font-weight: bold;
}


.filter-result-list {
	margin-top: 50px;
	margin-bottom: 80px;
	column-count: 2;
}
.filter-result-item {
	display: block;
	text-decoration: none;
	font-size: 24px;
	padding-bottom: 20px;
}


/*! FILTER !*/


.film-detail {
	margin-top: 60px;
	margin-bottom: 60px;
}

.film-detail-cols {
	// display: grid;
	// grid-template-columns: 1fr 420px;
	display: flex;
	align-items: flex-start;
	gap: 84px;
}

.film-detail-main {
	flex-shrink: 1;

}

.detail-prop {
	margin-bottom: 50px;
}

.film-detail-sidebar {
	width: 420px;
	padding: 24px;
	background-color: #B8DFE4;

}
.film-detail-sidebar-item {
	margin-bottom: 24px;
}
.film-detail-sidebar-item p {
	margin: 0;
	margin-bottom: 8px;
}


/*! PAGE SLIDER !*/

.page-slider-section {
	position: relative;
	margin-top: 90px;
	margin-bottom: 90px;
}
.page-slider-section .slider-arrows {
	position: absolute;
	width: 650px;
	display: flex;
	justify-content: space-between;
	top: 50%;
	left: 50%;
	z-index: 2;
	transform: translate(-50%,-50%);
}

.page-slider-item-pic {
	height: 300px;
}
.page-slider-item-pic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
/*! PAGE SLIDER !*/



/*! PERSON DETAIL PAGE !*/

.person-detail {
	margin-bottom: 120px;
}
.person-info {
	background-color: #B8DFE4;
	padding: 36px 46px;
	margin-bottom: 90px;
}
.person-info-cols {
	display: flex;
	gap: 30px;
	align-items: flex-start;
}
.person-info-main {
	flex-shrink: 1;
}
.person-info-title {
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 14px;
}

.person-info-pic {
	width: 350px;

}
.person-info-pic img {
	width: 100%;
}

/*! PERSON DETAIL PAGE !*/


/*! TABS !*/
.tab-list {
	display: grid;
	grid-template-columns: repeat(5,1fr);

}
.tab-item {
	text-align: center;
	font-size: 24px;
	position: relative;
	// padding: 20px 54px;
	cursor: pointer;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tab-item.active {
	font-weight: bold;
	box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
}
.tab-item.active::after {
	position: absolute;
	content: '';
	width: 100%;
	height: 50px;
	background-color: #fff;
	top: 75%;
	left: 0;
}
.tab-content {
}
.tab-content-item {
	padding: 50px 70px;
	box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
}
/*! TABS !*/

/*! NEWS LIST !*/

.news-list {
	display: grid;
	grid-template-columns: repeat(auto-fit,minmax(260px,1fr));
	gap: 12px;
	margin-bottom: 90px;
}
.news-item {
	box-shadow: 4px 4px 20px 0px #00000026;
	padding: 22px;
}
.news-item-pic {
	width: 100%;
	padding-top: 100%;
	position: relative;
	margin-bottom: 20px;
	display: flex;
}
.news-item-pic img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.news-item-title {
	font-weight: bold;
	margin-bottom: 20px;
	text-decoration: none;
	display: flex;
}
.news-item-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}



.news-item-more {
	cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    width: 35px;
    height: 35px;
    background-color: #B8DFE4;
    border-radius: 50%;
	background-image: url(../img/next-black.svg);
}
/*! NEWS LIST !*/


/*! PAGINATION !*/

.pagination {
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}
.pagination span {
	font-size: 24px;
	font-weight: bold;
}
.pagination-item {
	text-decoration: none;
}
.pagination-prev {
	background-image: url(../img/prev.svg);
	cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    background-color: #1e1e1e;
    border-radius: 50%;
	background-size: 7px;
}
.pagination-next {
	background-image: url(../img/next.svg);
	cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    background-color: #1e1e1e;
    border-radius: 50%;
	background-size: 7px;
}
/*! PAGINATION !*/

.news-detail-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 60px;
}

.articles-item {
	padding-bottom: 60px;
	margin-bottom: 60px;
	border-bottom: 1px dashed #1E1E1E;
	display: flex;
	flex-direction: column;

}
.articles-item-title {
	font-size: 32px;
	text-decoration: none;
	margin-bottom: 40px;
}
.articles-item-text {
	margin-bottom: 40px;
}

// @import 'modules/smartfilter.less';
// @import 'modules/forms.less';
// @import 'modules/dropdown.less';
// @import 'modules/breadcrumbs.less';
// @import 'modules/pagination.less';
// @import 'modules/popups.less';
// @import 'modules/checkout.less';
// @import 'modules/select.less';
// @import 'others.less';
// @import 'adaptive.less';